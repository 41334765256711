import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import SEO from "../components/seo"
import Loader from "../components/Loader"
import Stories from "../components/Stories"
import "../styles/pages-style/success-stories.scss"
import img from "../images/new-success-stories.svg"

const SuccessStories = () => {
	const successStoriesBlock = useRef(null)
	const [offsetSuccessStories, setOffsetSuccessStories] = useState(0)
	const [bgChangeSuccessStories, setBgChangeSuccessStories] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetSuccessStories ? setBgChangeSuccessStories(true) : setBgChangeSuccessStories(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let successStoriesRect = successStoriesBlock.current.getBoundingClientRect()
		let successStoriesPos = successStoriesRect.top - bodyRect.top
		setOffsetSuccessStories(successStoriesPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetSuccessStories, handleScroll])

	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<div>
					<Heading
						caption="Success Stories"
						text="We’ve never failed at a digital transformation. Not once."
                        img={img}
					/>
                    <div ref={successStoriesBlock} className={bgChangeSuccessStories ? 'change-bg-success-stories' : ''}>
                        <Stories />
                    </div>
				</div>
			</Layout>
		</div>
	)
}

export default SuccessStories
