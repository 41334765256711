import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

import img1 from "../../images/stories/a.png"
import img2 from "../../images/stories/b.png"
import img3 from "../../images/stories/c.png"
import img4 from "../../images/stories/d.png"
import img5 from "../../images/stories/e.png"
import img6 from "../../images/stories/f.png"
import img7 from "../../images/stories/g.png"
import img8 from "../../images/stories/h.png"
import img9 from "../../images/stories/i.png"
import img10 from "../../images/stories/j.png"
import img11 from "../../images/stories/k.png"

const storiesList = [
	{
		id: '1',
        title: 'From 14.1 to under a second',
        text: 'We improved the web speed of a large electronics retailer from 14.1 seconds to under a second',
        img: img1
	},
    {
		id: '2',
        title: 'From Cobol to a modern stack',
        text: 'We re-platformed a legacy undocumented COBOL system into a modern tech stack',
        img: img2
	},
    {
		id: '3',
        title: 'Saved 22% of shipping costs',
        text: 'We built a surface planning engine that saved a large retailer 22% of their shipping costs',
        img: img3
	},
    {
		id: '4',
        title: 'A one-click recall system ',
        text: 'We implemented a one-click recall system for a large food-manufacturing company',
        img: img4
	},
    {
		id: '5',
        title: 'Established traceability',
        text: 'We established traceability for a pharmaceutical distributor',
        img: img5
	},
    {
		id: '6',
        title: 'App for a fleet management',
        text: 'We built a mobile app for a fleet management company that manages everything the driver needs en route.',
        img: img6
	},
    {
		id: '7',
        title: 'E-commerce platform',
        text: 'We built a complete e-commerce platform for a multi-company corporation. It led the corporation to the best holiday season in history',
        img: img7
	},
    {
		id: '8',
        title: 'From paper to fully digital',
        text: 'We built an ERP for a construction company that took them from fully paper to fully digital, including their sales and accounting departments',
        img: img8
	},
    {
		id: '9',
        title: 'Eye-doctor automated ERP',
        text: 'We built an ERP for an eye-doctor multi-practice corporation that digitized and automated all their processes',
        img: img9
	},
    {
		id: '10',
        title: 'A-Z system for medical facilities',
        text: 'We are developing an A-Z system for medical facilities specializing in the ABA industry',
        img: img10
	},
    {
		id: '11',
        title: 'A-Z system for medical staffing',
        text: 'We are developing an A-Z system for medical staffing',
        img: img11
	},
]

const Stories = () => {

	return (
		<div className="stories">
			<div className="container">
                {storiesList.map((item) => {
                    return (
                        <Fade bottom duration={1000} key={item.id}>
                            <div className="stories__row" >
                                <div className="stories__info">
                                    <span className="caption stories__caption">{item.title}</span>
                                    <p>{item.text}</p>
                                </div>
                                <div className="stories__img">
                                    <img src={item.img} alt="" />
                                </div>
                            </div>
                        </Fade>
                    )
                })}
			</div>
		</div>
	)
}

export default Stories
